const contextMap: Record<string, any> = {
  Hero: defineAsyncComponent(
    () => import('~/components/sections/hero/hero.animation.vue'),
  ),
  heroSectionTitle: defineAsyncComponent(
    () =>
      import(
        '~/components/sections/hero/context/heroSectionTitle/heroSectionTitle.vue'
      ),
  ),
  HeroAnimationScaleScrollScrub: defineAsyncComponent(
    () =>
      import(
        '~/components/sections/hero/context/heroAnimationScaleScrollScrub/heroAnimationScaleScrollScrub.vue'
      ),
  ),
  HeroAnimationScaleScroll: defineAsyncComponent(
    () =>
      import(
        '~/components/sections/hero/context/heroAnimationScaleScroll/heroAnimationScaleScroll.vue'
      ),
  ),
  GridAnimationEnter: defineAsyncComponent(
    () => import('~/components/system/grid/context/gridAnimationEnter.vue'),
  ),
  QuoteAnimationScroll: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/quote/context/quoteAnimationScroll/quoteAnimationScroll.vue'
      ),
  ),
  MapAnimationCameraRotate: defineAsyncComponent(
    () => import('~/components/ui/map/context/mapAnimationCameraRotate.vue'),
  ),
  MediaAnimationParallax: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/media/context/mediaAnimationParallax/mediaAnimationParallax.vue'
      ),
  ),
  MediaAnimationParallaxInverse: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/media/context/mediaAnimationParallax/mediaAnimationParallaxInverse.vue'
      ),
  ),
  MediaAnimationScaleScroll: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/media/context/mediaAnimationScaleScroll/mediaAnimationScaleScroll.vue'
      ),
  ),
  MediaAnimationRevealFromLeft: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/media/context/mediaAnimationReveal/mediaAnimationRevealFromLeft.vue'
      ),
  ),
  MediaAnimationRevealFromRight: defineAsyncComponent(
    () =>
      import(
        '~/components/ui/media/context/mediaAnimationReveal/mediaAnimationRevealFromRight.vue'
      ),
  ),
}
export default contextMap
