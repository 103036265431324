const blocksMap: Record<string, any> = {
  AccordionModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/accordion/accordion.vue'),
  ),
  BodyModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/body/body.vue'),
  ),
  CardModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/card/card.vue'),
  ),
  FormModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/form/form.vue'),
  ),
  GridSectionRecord: defineAsyncComponent(
    () => import('~/components/system/grid/grid.vue'),
  ),
  TabGridSectionRecord: defineAsyncComponent(
    () => import('~/components/system/grid/grid.vue'),
  ),
  HeroSectionRecord: defineAsyncComponent(
    () => import('~/components/sections/hero/hero.vue'),
  ),
  ImageModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/image/image.vue'),
  ),
  MapModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/map/map.vue'),
  ),
  MediaModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/media/media.vue'),
  ),
  QuoteModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/quote/quote.vue'),
  ),
  ServiceContactRecord: defineAsyncComponent(
    () => import('~/components/sections/serviceContact/serviceContact.vue'),
  ),
  SliderModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/slider/slider.vue'),
  ),
  SpacerModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/spacer/spacer.vue'),
  ),
  TeamMembersSectionRecord: defineAsyncComponent(
    () => import('~/components/sections/teamMembers/teamMembers.vue'),
  ),
  TextModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/text/text.vue'),
  ),
  TabsModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/tabs/tabs.vue'),
  ),
}

export default blocksMap
