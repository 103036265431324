<template>
  <Context
    v-if="block.advanced?.context && __blockMap[type]"
    :context="block.advanced?.context"
  >
    <component
      :is="__blockMap[type]"
      v-bind="dataMap[type] ? dataMap[type](block) : block"
    />
  </Context>

  <component
    v-else-if="__blockMap[type]"
    :is="__blockMap[type]"
    v-bind="dataMap[type] ? dataMap[type](block) : block"
  />

  <div v-else>
    <h2>Block not found</h2>
    <pre>{{ block }}</pre>
  </div>
</template>
<script setup lang="ts">
import Context from '~/components/system/context/context.vue'
import __blockMap from './block.map.js'
import type { TBlock } from './block.type'

const props = withDefaults(defineProps<TBlock>(), {})

const dataMap: Record<string, Function> = {
  FormModuleRecord: (block: any) => {
    return block.json
  },
}

const id = props.block.advanced?.id ?? props.block.id
delete props.block.id
const type = props.block.__typename ?? props.type
</script>
