<template>
  <component v-if="context && __contextMap[context as string]" :is="__contextMap[context as string]">
    <slot />
  </component>
  <template v-else>
    <div>Missing context: {{ context }}</div>
  </template>
</template>
<script setup lang="ts">
import __contextMap from './context.map.js';

defineProps({
  context: {
    type: String,
  },
})
</script>
