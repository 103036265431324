<template>
  <div class="blocks">
    <div
      v-for="block in blocks"
      :class="`blocks_block ${block?.theme?.cs ? `-cs-${block?.theme.cs}` : ''}`"
    >
      <Block :block="block" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Block from '~/components/system/block/block.vue'
import './blocks.css'
import type { TBlocks } from './blocks.type'

withDefaults(defineProps<TBlocks>(), {
  blocks: () => [],
})
</script>
